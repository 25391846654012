var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { staticClass: "mx-0 px-6 py-0" },
    [
      _vm.field.type === "string" &&
      !_vm.field.value.includes("phone") &&
      _vm.field.page === _vm.currentPage
        ? _c("v-text-field", {
            attrs: {
              label: _vm.field.label,
              outlined: "",
              "background-color": "#fff",
              dense: ""
            },
            model: {
              value: _vm.payload["" + _vm.field.value],
              callback: function($$v) {
                _vm.$set(_vm.payload, "" + _vm.field.value, $$v)
              },
              expression: "payload[`${field.value}`]"
            }
          })
        : _vm._e(),
      _vm.field.type === "string" &&
      _vm.field.value.includes("phone") &&
      _vm.field.page === _vm.currentPage
        ? _c("v-text-field", {
            attrs: {
              type: "phone",
              dense: "",
              label: "Phone Number *",
              outlined: ""
            },
            on: { change: _vm.formatPhone },
            model: {
              value: _vm.payload["" + _vm.field.value],
              callback: function($$v) {
                _vm.$set(_vm.payload, "" + _vm.field.value, $$v)
              },
              expression: "payload[`${field.value}`]"
            }
          })
        : _vm._e(),
      _vm.field.type === "date" && _vm.field.page === _vm.currentPage
        ? _c(
            "v-menu",
            {
              attrs: {
                "close-on-content-click": true,
                transition: "scale-transition",
                "offset-y": "",
                "nudge-left": "25",
                "nudge-top": "25",
                "max-width": "290px",
                "min-width": "290px"
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-text-field",
                          _vm._g(
                            {
                              attrs: {
                                label: _vm.field.label,
                                "prepend-inner-icon": "mdi-calendar",
                                outlined: "",
                                "background-color": "#fff",
                                readonly: "",
                                dense: ""
                              },
                              model: {
                                value: _vm.payload["" + _vm.field.value],
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.payload,
                                    "" + _vm.field.value,
                                    $$v
                                  )
                                },
                                expression: "payload[`${field.value}`]"
                              }
                            },
                            on
                          )
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                2180898022
              )
            },
            [
              _c("v-date-picker", {
                attrs: { "no-title": "" },
                model: {
                  value: _vm.payload["" + _vm.field.value],
                  callback: function($$v) {
                    _vm.$set(_vm.payload, "" + _vm.field.value, $$v)
                  },
                  expression: "payload[`${field.value}`]"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.field.type === "boolean" && _vm.field.page === _vm.currentPage
        ? _c("v-checkbox", {
            attrs: { label: _vm.field.label, dense: "" },
            model: {
              value: _vm.payload["" + _vm.field.value],
              callback: function($$v) {
                _vm.$set(_vm.payload, "" + _vm.field.value, $$v)
              },
              expression: "payload[`${field.value}`]"
            }
          })
        : _vm._e(),
      _vm.field.type === "select" && _vm.field.page === _vm.currentPage
        ? _c("v-autocomplete", {
            attrs: {
              label: _vm.field.label,
              outlined: "",
              items: _vm.list,
              "item-text": [
                "ad_group_id",
                "owner_id",
                "division_id",
                "sub_group_id"
              ].includes(_vm.field.value)
                ? "party_name"
                : "name",
              "item-value": [
                "ad_group_id",
                "owner_id",
                "division_id",
                "sub_group_id"
              ].includes(_vm.field.value)
                ? "party_id"
                : "id",
              dense: ""
            },
            model: {
              value: _vm.payload["" + _vm.field.value],
              callback: function($$v) {
                _vm.$set(_vm.payload, "" + _vm.field.value, $$v)
              },
              expression: "payload[`${field.value}`]"
            }
          })
        : _vm._e(),
      _vm.field.type === "array" && _vm.field.page === _vm.currentPage
        ? _c("v-autocomplete", {
            attrs: {
              label: _vm.field.label,
              multiple: "",
              outlined: "",
              items: _vm.list,
              "item-text": "name",
              "item-value": "id",
              dense: ""
            },
            model: {
              value: _vm.payload["" + _vm.field.value],
              callback: function($$v) {
                _vm.$set(_vm.payload, "" + _vm.field.value, $$v)
              },
              expression: "payload[`${field.value}`]"
            }
          })
        : _vm._e(),
      _vm.field.type === "section" && _vm.field.page === _vm.currentPage
        ? _c(
            "v-container",
            { staticClass: "mx-0 px-0 pt-0 pb-4" },
            [
              _c(
                "v-col",
                { staticClass: "mx-0 px-0 py-0", attrs: { align: "start" } },
                [
                  _c("div", { staticClass: "font-weight-bold" }, [
                    _vm._v(" " + _vm._s(_vm.field.label) + " ")
                  ]),
                  _c(
                    "v-row",
                    _vm._l(_vm.field.sectionFields, function(subfield) {
                      return _c(
                        "v-col",
                        {
                          key: subfield.value,
                          staticClass: "mx-0 px-3 pt-4 pb-0"
                        },
                        [
                          subfield.type === "string" &&
                          !subfield.value.includes("phone")
                            ? _c("v-text-field", {
                                attrs: {
                                  label: subfield.label,
                                  outlined: "",
                                  "background-color": "#fff",
                                  dense: ""
                                },
                                model: {
                                  value:
                                    _vm.payload["" + _vm.field.value][
                                      "" + subfield.value
                                    ],
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.payload["" + _vm.field.value],
                                      "" + subfield.value,
                                      $$v
                                    )
                                  },
                                  expression:
                                    "payload[`${field.value}`][`${subfield.value}`]"
                                }
                              })
                            : _vm._e(),
                          subfield.value.includes("phone")
                            ? _c("v-text-field", {
                                attrs: {
                                  type: "phone",
                                  dense: "",
                                  label: "Phone Number",
                                  outlined: ""
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.formatPhone(
                                      $event,
                                      _vm.field.value,
                                      subfield.value
                                    )
                                  }
                                },
                                model: {
                                  value:
                                    _vm.payload["" + _vm.field.value][
                                      "" + subfield.value
                                    ],
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.payload["" + _vm.field.value],
                                      "" + subfield.value,
                                      $$v
                                    )
                                  },
                                  expression:
                                    "payload[`${field.value}`][`${subfield.value}`]"
                                }
                              })
                            : _vm._e(),
                          subfield.type === "select"
                            ? _c("v-autocomplete", {
                                attrs: {
                                  label: subfield.label,
                                  items:
                                    subfield.value === "paying_option_id"
                                      ? _vm.paymentTypes
                                      : _vm.list,
                                  outlined: "",
                                  "item-text": "name",
                                  "item-value": "id",
                                  dense: ""
                                },
                                model: {
                                  value:
                                    _vm.payload["" + _vm.field.value][
                                      "" + subfield.value
                                    ],
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.payload["" + _vm.field.value],
                                      "" + subfield.value,
                                      $$v
                                    )
                                  },
                                  expression:
                                    "payload[`${field.value}`][`${subfield.value}`]"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }