<template>
  <v-col class="mx-0 px-6 py-0">
    <v-text-field 
      v-if="field.type === 'string' && !field.value.includes('phone') && field.page === currentPage" 
      :label="field.label"
      v-model="payload[`${field.value}`]"
      outlined
      background-color="#fff"
      dense>
    </v-text-field>
    <v-text-field
      v-if="field.type === 'string' && field.value.includes('phone') && field.page === currentPage"
      type="phone"
      dense
      v-model="payload[`${field.value}`]"
      @change="formatPhone"
      label="Phone Number *"
      outlined>
    </v-text-field>
    <v-menu
      v-if="field.type === 'date' && field.page === currentPage" 
        :close-on-content-click="true"
        transition="scale-transition"
        offset-y
        nudge-left="25"
        nudge-top="25"
        max-width="290px"
        min-width="290px">
        <template v-slot:activator="{ on }">
          <v-text-field
            v-on="on"
            :label="field.label"
            v-model="payload[`${field.value}`]"
            prepend-inner-icon="mdi-calendar"
            outlined
            background-color="#fff"
            readonly
            dense>
          </v-text-field>
        </template>
        <v-date-picker
          v-model="payload[`${field.value}`]"
          no-title>
        </v-date-picker>
      </v-menu>
    <v-checkbox 
      v-if="field.type === 'boolean' && field.page === currentPage"
      :label="field.label"
      v-model="payload[`${field.value}`]"
      dense>
    </v-checkbox>
    <v-autocomplete 
      v-if="field.type === 'select' && field.page === currentPage"
      :label="field.label"
      v-model="payload[`${field.value}`]"
      outlined
      :items="list"
      :item-text="['ad_group_id', 'owner_id', 'division_id', 'sub_group_id'].includes(field.value) ? 'party_name' : 'name'"
      :item-value="['ad_group_id', 'owner_id', 'division_id', 'sub_group_id'].includes(field.value)  ? 'party_id' : 'id'"
      dense>
    </v-autocomplete>
    <v-autocomplete 
      v-if="field.type === 'array' && field.page === currentPage"
      :label="field.label"
      multiple
      v-model="payload[`${field.value}`]"
      outlined
      :items="list"
      item-text="name"
      item-value="id"
      dense>
    </v-autocomplete>
    <v-container class="mx-0 px-0 pt-0 pb-4" v-if="field.type === 'section' && field.page === currentPage">
      <v-col class="mx-0 px-0 py-0" align="start">
        <div class="font-weight-bold">
          {{ field.label }}
        </div>
        <v-row>
          <v-col class="mx-0 px-3 pt-4 pb-0" v-for="subfield in field.sectionFields" :key="subfield.value">
            <v-text-field
              v-if="subfield.type === 'string' && !subfield.value.includes('phone')"
              :label="subfield.label"
              v-model="payload[`${field.value}`][`${subfield.value}`]"
              outlined
              background-color="#fff"
              dense>
            </v-text-field>
            <v-text-field
              v-if="subfield.value.includes('phone')"
              type="phone"
              dense
              v-model="payload[`${field.value}`][`${subfield.value}`]"
              @change="formatPhone($event, field.value, subfield.value)"
              label="Phone Number"
              outlined>
            </v-text-field>
            <v-autocomplete 
              v-if="subfield.type === 'select'"
              :label="subfield.label"
              v-model="payload[`${field.value}`][`${subfield.value}`]"
              :items="subfield.value === 'paying_option_id' ? paymentTypes: list"
              outlined
              item-text="name"
              item-value="id"
              dense>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-col>
    </v-container>
  </v-col>
</template>
<script>
import kvApi from "@/axios/key-values-endpoint.js"
import Party from '@/axios/party-endpoint.js'
import Search from '@/axios/search-endpoint'
import PhoneNumber from 'awesome-phonenumber'
import { displayAlert } from '@/mixins/alert'

export default {
  name: "StoreFormField",
  props: ['field', 'currentPage', 'params'],
  data() {
    return {
      partyAttributes: [],
      partyAttributeGroups: {},
      partyAttributeValues: [],
      list: [],
      paymentTypes: [],
      region: {
        code: 'US',
        name: 'United States of America'
      }
    }
  },
  mixins: [displayAlert],
  watch: {
    payload: {
      handler() {
        this.$emit('update', this.payload)
      },
      deep: true
    }
  },
  computed: {
    payload() {
      return this.params;
    }
  },
  created() {
    if (this.field.type === "select" || this.field.type === "array" || (this.field.sectionFields?.length > 1 && this.field.sectionFields?.map(s => s.type).includes('select')))
    this.getValues(this.field.value)
  },
  methods: {
    formatPhone (data, key, subkey) {
      if (subkey != null) {
        this.payload[`${key}`][`${subkey}`] = new PhoneNumber(data, this.region.code).getNumber('international')
      } else {
        this.payload[`${key}`] = new PhoneNumber(data, this.region.code).getNumber('international')
      }
    },
    async getValues(value) {
  const valueMap = {
    "ad_break_day_id": "DAY_OF_WEEK",
    "tpr_break_day_id": "DAY_OF_WEEK",
    "awg_center_deliver_day_ids": "DAY_OF_WEEK",
    "division_id": "DISTRIBUTION_CENTER",
    "billing_program_type_id": "BILLING_PROGRAM_TYPE",
    "group_manager_id": "GROUP_MANAGER",
    'pos_provider_id':"POS_SOFTWARE_PROVIDER",
    "advertising_service_id": "ADVERTISING_SERVICE",
    "loyalty_digital_coupon_customer_id": "LOYALTY_COUPON_PROGRAM" ,
    "hosting_file_participation_id": "HOSTING_FILE_OPTION",
    "other_sponsored_programs": "SPONSORED_PROGRAMS",
    "store_format_id": "STORE_TYPE",
    "owner_id": "OWNER_GROUP",
    "ad_group_id": "AD_GROUP",
    "sub_group_id": "SUBGROUP",
    "paying_option_id": "PAYMENT_OPTION"
  };

  const string = valueMap[value] || "";

  if (this.field.sectionFields?.some(s => s.value === 'state')) {
    try {
      this.list = (await kvApi.keyValues.getList("STATE_PROVINCE_US", 100, 0)).data;
      this.paymentTypes = (await kvApi.keyValues.getList("PAYMENT_OPTION", 100, 0)).data;
    } catch (err) {
      this.handleError(err);
    }
  } else if (["AD_GROUP", "OWNER_GROUP", "DISTRIBUTION_CENTER", 'SUBGROUP'].includes(string)) {
    try {
      this.list = (await Search.customerSearch(string, "or")).data;
    } catch (err) {
      this.handleError(err);
    }
  } else if (['SUBGROUP'].includes(string)) {
    try {
      this.list = (await Party.searchParties(string)).data;
    } catch (err) {
      this.handleError(err);
    }
  } else {
    const predefinedLists = {
      "SPONSORED_PROGRAMS": [
        {name: 'eGrowcery', id:"EGROWCERY"}, {name: 'Adergy Program', id:'ADERGY_PROGRAM'}, {name: 'Neptune Program', id: 'NEPTUNE_PROGRAM'}
      ]
    };
    
    if (predefinedLists[string]) {
      this.list = predefinedLists[string];
    } else if (string) {
      try {
        this.list = (await kvApi.keyValues.getList(string, 100, 0)).data;
      } catch (err) {
        this.handleError(err);
      }
    }
  }
}
  }
}
</script>