<template>
  <v-dialog :value="value" @click:outside="close" persisten max-width="1000px">
    <v-card>
      <v-stepper v-model="view">
        <v-stepper-header>
          <v-stepper-step step="1" :complete="false">
            Owner Information
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="2" :complete="false">
            Store Information
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="3" :complete="false">
            Store Information (cont.)
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="4" :complete="false">
            Technology
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="5" :complete="false">
            Marketing/Advertising
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
         <!-- TODO Loop through steppers to reduced amount of repeated code. -->
         <!-- Looping currently doesn't display fields, need to figure out that. -->
         <!-- TODO II: Validate forms by each page. Disable continue/submit buttons if not all fields on current step are filled out. -->
          <!-- <v-stepper-content v-for="page, pageIndex in groupFields" :key="pageIndex" :step="pageIndex" class="ma-4 pa-0">
            <v-col class="pa-6" align="center">
              <v-row v-for="group, i in page" :key="i">
                <StoreFormField
                v-for="field in group"
                :key="field.value"
                :field="field"
                :currentPage="pageIndex"
                :params="params"
                @update="updateParams"
                />
              </v-row>
          </v-col>
          <v-row class="mr-6 my-2 justify-space-between">
            <v-btn v-if="pageIndex === 1" @click="close">Close</v-btn>
            <v-btn @click="back">Back</v-btn>
            <v-btn @click="forward">Continue</v-btn>
          </v-row>
          </v-stepper-content> -->
          <v-stepper-content step="1" class="ma-4 pa-0">
            <v-col class="pa-6" align="center">
              <v-row v-for="(group, i) in groupFields[1]" :key="i">
                <StoreFormField
                v-for="field in group"
                :key="field.value"
                :field="field"
                :currentPage="1"
                :params="params"
                @update="updateParams"
                />
              </v-row>
          </v-col>
          <v-row class="mx-8 my-2 justify-space-between">
            <v-btn @click="close">Close</v-btn>
            <v-btn @click="forward">Continue</v-btn>
          </v-row>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-col class="py-4" align="center">
              <v-row class="my-2" v-for="(group, i) in groupFields[2]" :key=i>
                <StoreFormField
                v-for="field in group"
                :key="field.value"
                :field="field"
                :currentPage="2"
                :params="params"
                @update="updateParams"
               />
              </v-row>
            </v-col>
            <v-row class="mx-8 my-2 justify-space-between">
              <v-btn @click="back">Back</v-btn>
              <v-btn @click="forward">Continue</v-btn>
            </v-row>
          </v-stepper-content>
          <v-stepper-content step="3">
            <v-col class="py-4" align="center">
              <v-row v-for="(group, i) in groupFields[3]" :key=i>
                <StoreFormField
                v-for="field in group"
                :key="field.value"
                :field="field"
                :currentPage="3"
                :params="params"
                @update="updateParams"
                 />
              </v-row>
            </v-col>
            <v-row class="mx-8 my-2 justify-space-between">
              <v-btn @click="back">Back</v-btn>
              <v-btn @click="forward">Continue</v-btn>
            </v-row>
          </v-stepper-content>
          <v-stepper-content step="4">
            <v-col class="py-4" align="center">
              <v-row v-for="(group, i) in groupFields[4]" :key=i>
                <StoreFormField
                v-for="field in group"
                :key="field.value"
                :field="field"
                :currentPage="4"
                :params="params"
                @update="updateParams"
                 />
              </v-row>
            </v-col>
            <v-row class="mx-8 my-2 justify-space-between">
              <v-btn @click="back">Back</v-btn>
              <v-btn @click="forward">Continue</v-btn>
            </v-row>
          </v-stepper-content>
          <v-stepper-content step="5">
            <v-col class="py-4" align="center">
              <v-row v-for="(group, i) in groupFields[5]" :key=i>
                <StoreFormField
                v-for="field in group"
                :key="field.value"
                :field="field"
                :currentPage="5"
                :params="params"
                @update="updateParams"
                 />
              </v-row>
            </v-col>
            <v-row class="mx-8 my-2 justify-space-between">
              <v-btn @click="back">Back</v-btn>
              <v-btn @click="createStore">Create Store</v-btn>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </v-dialog>
</template>
<script>
import StoreFormField from '@/components/create_store_field.vue'
import Party from '@/axios/party-endpoint.js'
import { displayAlert } from '@/mixins/alert'

export default {

  name: 'CreateStoreModal',
  props: [
    'value'
  ],
  mixins: [displayAlert],
  components: { StoreFormField },
  data() {
    return {
      view: 1,
      params: {
        "arg_owner_group_name": null,
        "legal_name": null,
        "owner_id": {},
        "corporate_address": {
          "street": null,
          "city": null,
          "state": null,
          "zip_code": null
        },
          "store_number": null,
          "name": null,
          "start_date": null,
          "group_manager_id": null,
          "division_id": null,
          "store_format_id": null,
          "ad_group_id": null,
          "sub_group_id": null,
          "weekly_sales": null,
          "square_footage": null,
          "store_address": {
            "street": null,
            "city": null,
            "state": null,
            "zip_code": null,
            "phone": "",
          },
          "key_store_contact": {
            "name": "",
            "email_address": ""
          },
          "billing_program_type_id": null,
          "ad_break_day_id": null,
          "tpr_break_day_id": null,
          "ad_length": null,
          "awg_center_deliver_day_ids": [],
          "arg_vmc_program": false,
          "ac_nielsen_program": false,
          "arg_ntouch_program": false,
          "tpr_tag_generator": "",
          "rebate": {
            "paying_option_id": "",
            "street": "",
            "city": "",
            "state": "",
            "zip_code": ""
          },
          "hosting_file_participation_id": "",
          "it_contact": {
            "name": "",
            "email_address": "",
            "phone_number": ""
          },
          "pos_provider_id": {},
          "pos_contact": {
            "name": "",
            "email": "",
            "phone": "",
            "equipment": ""
          },
          "open_on_sunday": false,
          "open_24_hours": false,
          "loyalty_digital_coupon_customer_id": "",
          "advertising_service_id": "",
          "alliance_link": false,
          "other_sponsored_programs": []
      }
    }
  },
  computed: {
    fields() {
      return [
      // PAGE 1
      { label:"ARG Owner Group Name", value:"arg_owner_group_name", required: true,  type:"string", page:1 },
      { label:"Legal/Corporate Name", value:"legal_name", required: true,  type:"string", page: 1 } ,
      { label:"Owner", value:"owner_id", required: true, type:"select", page: 1 },
      {label:"Corporate Address", required: true, value:"corporate_address", type:"section", page:1, sectionFields: [
      { label:"Street", value:"street",  type:"string", page: 1 },
      { label:"City", value:"city",  type:"string", page: 1 },
      { label:"State", value:"state",  type:"select", page: 1 },
      { label:"Zip Code", value:"zip_code",  type:"string", page: 1 },
      ]},
      // PAGE 2
      { label:"ARG Store Number", value:"store_number", required: true,  type:"string", page: 2 },
      { label:"Store Name", required: true, value:"name",  type:"string", page: 2 },
      { label:"Store Start Date/First Ad Date", required: true, value:"start_date", type:"date", page: 2 },
      { label:"Group Manager", value:"group_manager_id", type:"select", page: 2 },
      { label:"AWG Division", value:"division_id",  type:"select", page: 2 },
      { label:"Store Format", value:"store_format_id", required: true, type:"select", page: 2 },
      { label:"Ad Group", value:"ad_group_id", required: true, type:"select", page: 2 },
      { label:"Sub Group Name", value:"sub_group_id",  type:"select", page: 2 },
      { label:"Weekly Store Sales", value:"weekly_sales", required: true, type:"string", page: 2 },
      { label:"Store Square Footage", value:"square_footage", required: true, type:"string", page: 2 },
      { label:"Store Address", value:"store_address",  type:"section", page: 2, required: true, sectionFields: [
        { label:"Street", value:"street", required: true, type:"string", page: 2 },
        { label:"City", value:"city", required: true, type:"string", page: 2 },
        { label:"State", value:"state", required: true, type:"select", page: 2 },
        { label:"Zip Code", value:"zip_code", required: true, type:"string", page: 2 },
        {label:"Phone Number", value:"phone", required: true, type:"string", page: 2},
      ]
    },
      {label:"Key Store Contact", value:"key_store_contact",  type:"section", page: 2, sectionFields: [
        { label:"Name", value:"name", required: true, type:"string", page:2 },
        { label:"Email", value:"email_address", required: true, type:"string", page:2 },
      ]},
      // PAGE 3
      { label:"Billing Program Type", value:"billing_program_type_id", required: true, type:"select", page:3 },
      { label:"Ad Break Day", value:"ad_break_day_id", required: true, type:"select", page:3 },
      { label:"TPR Break Day", value:"tpr_break_day_id", required: true, type:"select", page:3 },
      { label:"Ad Length", value:"ad_length", required: true, type:"string", page:3 },
      { label:"AWG Center Deliver Days", value:"awg_center_deliver_day_ids", required: true, type:"array", page:3 },
      { label:"AC Nielson Program", value:"ac_nielsen_program", type:"boolean", page:3 },
      { label:"ARG VMC Program", value:"arg_vmc_program", type:"boolean", page:3 },
      { label:"nTouch Program", value:"arg_ntouch_program", type:"boolean", page:3 },
      { label:"TPR Tag Generator Offset (Days)", value:"tpr_tag_generator", type:"string", page:3 },
      { label:"Rebate", value:"rebate", type:"section", page:3, sectionFields: [
      { label:"Paying Option", value:"paying_option_id", required: true, type:"select", page:2 },
      { label:"Street", value:"street",  type:"string", page:2 },
      { label:"City", value:"city",  type:"string", page:2 },
      { label:"State", value:"state",  type:"select", page:2 },
      { label:"Zip Code", value:"zip_code",  type:"string", page:2 },
      ]},
      // PAGE 4
      { label:"Hosting File Participation", value:"hosting_file_participation_id", type:"select", page:4 },
      { label:"IT Contact", value:"it_contact",  type:"section", page:4, sectionFields: [
      { label:"Name", value:"name", required: true, type:"string", page:4 },
      { label:"Email Address", value:"email_address", required: true, type:"string", page:4 },
      { label:"Phone Number", value:"phone_number", required: true, type:"string", page:4 },
      ]},
      { label:"POS Provider", value:"pos_provider_id",  type:"select", page: 4 },
      {label:"POS Contact", value:"pos_contact",  type:"section", page: 4, required: true, sectionFields: [
        { label:"Name", value:"name", required: true, type:"string", page: 4 },
        { label:"Email", value:"email", required: true, type:"string", page: 4 },
        { label:"Phone Number", value:"phone", required: true, type:"string", page: 4 },
        { label:"Equipment", value:"equipment", required: true, type:"string", page: 4 },
      ]
    },
      // Page 5
      { label:"Open On Sunday", value:"open_on_sunday",  type:"boolean", page: 5 },
      { label:"Open 24 Hours", value:"open_24_hours",  type:"boolean", page: 5 },
      { label:"Alliance Link", value:"alliance_link", type:"boolean", page: 5 },
      { label:"Loyaly Digital Coupon Customer", value:"loyalty_digital_coupon_customer_id",  type:"select", page: 5 },
      { label:"Advertising Services", value:"advertising_service_id", required: true, type:"select", page: 5 },
      { label:"Other Sponsored Programs", value:"other_sponsored_programs", required: true, type:"array", page: 5 },
      ]
    },
  groupFields() {
  let groupedFields = {};

  this.fields.forEach(field => {
    if (!groupedFields[field.page]) {
      groupedFields[field.page] = [];
    }

    if (field.type === "section") {
      groupedFields[field.page].push([field]);
    } else {
      let lastGroup = groupedFields[field.page][groupedFields[field.page].length - 1];
      
      if (!lastGroup || lastGroup.length >= 3 || lastGroup[0].type === "section") {
        groupedFields[field.page].push([field]);
      } else {
        lastGroup.push(field);
      }
    }
  });
  return groupedFields;
},

pageIsValid() {
  return this.validatePageValues(this.groupFields[this.view])
}
  },
  methods: {
    updateParams(payload) { 
      this.params = payload
    },
    
   flattenObject(obj, parentKey = '', result = {}) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            let newKey = parentKey ? `${parentKey}_${key}` : key;
            if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
                this.flattenObject(obj[key], newKey, result);
            } else {
                result[newKey] = Array.isArray(obj[key]) ? obj[key].join('; ') : obj[key];
            }
        }
    }
    return result;
},
convertToCSV(objArray) {
    const flatObj = this.flattenObject(objArray);
    const headers = Object.keys(flatObj).join(',');
    const values = Object.values(flatObj).map(val => `"${val}"`).join(',');

    const csvString = `${headers}\n${values}`;
    this.uploadCSV(csvString)
},

async uploadCSV(csvString) {
    try {
        let blob = new Blob([csvString], { type: "text/csv" });

        let file = new File([blob], "data.csv", { type: "text/csv" });
        let fileName = `${this.params.name.replace(/\s+/g, "_").replace(/'/g, "")}.csv`

        await Party.uploadStoreCSV(file, fileName).then(() => {
          this.emitAlert(true, 'success','Store information submitted.')
          this.view = 1
        })
    } catch (error) {
      this.handleError(error);
    }
},

// TODO: Rework page validation to include subfields.
validatePageValues(page) {
  const arr = []
  page.map(group => group.map(field => {
    if (field.required && (this.params[`${field.value}`] === null || this.params[`${field.value}`] === '') ) {
      arr.push(false)
    } else {
      arr.push(true)
    }
  }))
  return !arr.includes(false)
},
forward() {
  this.view++
},
back() {
  this.view--
},
close() {
  this.view = 1
  this.$emit('close')
},
createStore() {
  this.convertToCSV(this.params)
  this.$emit('close')
}
  }
}
</script>